import React, { useState } from 'react';
import { Navbar, Nav, Button, Container, Dropdown, DropdownButton } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'; // Import useLocation
import AddCategoryPopup from './AddCategoryPopup';
import CategoryDropdownItem from './CategoryDropdownItem';
import customIcon from '../assets/Grumpy.png';

const AppNavbar = ({ onToggleMode, onFilterByCategory, onStartDaily, isDailyMode, categories, onAddCategory, onDeleteCategory, selectedCategory }) => {
  const location = useLocation(); // Get the current path
  const showButtons = location.pathname === '/'; // Conditionally show buttons

  const [showCategoryPopup, setShowCategoryPopup] = useState(false);

  const handleCategorySelect = (category) => {
    onFilterByCategory(selectedCategory === category ? null : category);
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" fixed="top" className="w-100">
      <Container fluid>
        <Navbar.Brand href="#home">
          <img
            src={customIcon}
            alt="Custom Icon"
            style={{ width: '30px', height: '30px', marginRight: '10px' }}
          />
          GMC
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"></Nav>
          {showButtons && (
            <Nav>
              <Button variant={isDailyMode ? "success" : "outline-light"} className="me-2" onClick={onToggleMode}>
                {isDailyMode ? 'Switch to Active Mode' : 'Switch to Daily Mode'}
              </Button>
              <DropdownButton variant={selectedCategory ? "warning" : "outline-light"} className="me-2" title={selectedCategory ? selectedCategory.name : "Category"}>
                <Dropdown.Item style={{ textAlign: "center" }} onClick={() => setShowCategoryPopup(true)}>+</Dropdown.Item>
                <Dropdown.Divider />
                {categories.map((category, index) => (
                  <CategoryDropdownItem
                    key={index}
                    category={category}
                    isActive={selectedCategory === category}
                    onSelect={handleCategorySelect}
                    onDelete={onDeleteCategory}
                  />
                ))}
              </DropdownButton>
              <Button variant="outline-light" onClick={onStartDaily} disabled={isDailyMode}>
                Start Daily
              </Button>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
      <AddCategoryPopup
        show={showCategoryPopup}
        onClose={() => setShowCategoryPopup(false)}
        onAddCategory={onAddCategory}
      />
    </Navbar>
  );
};

export default AppNavbar;
